<template>
  <span id="toolbarContainer">
    <v-expand-x-transition>
      <v-layout v-show="menu.hamburguer" wrap>
        <v-navigation-drawer
          v-model="menu.hamburguer"
          absolute
          temporary
          style="width: 100vw"
          color="primary"
        >
          <v-row
            align="start"
            justify="center"
            :key="app.reloadableKey"
          >
            <v-col cols="12">
              <v-list v-if="user" class="transparent pa-0">
                <v-list-item class="mb-2 pa-8">
                  <v-list-item-avatar style="margin-right: 0" size="50">
                    <img :src="user.profilePicture" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="ml-2 user-name">
                      <v-row>
                        <v-col cols="9">
                          {{ $auth.user() && $auth.user().name.substring(0,16) }}{{ $auth.user() && $auth.user().name.length > 18 ? '...' : '' }}
                        </v-col>
                        <v-col cols="3">
                          <img
                            @click="toggleShowHamgurber(false)"
                            style="height: 25px; width: 30px"
                            src="/img/icons/sidebar/right-arrow.svg"
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <div id="menuOpcoes" v-for="(menu, m) in $auth.getMenuOpcoes()" :key="m">
                  <v-list-item
                    class="mb-1 pl-8"
                    @click="openMenu(menu)"
                  >
                    <v-list-item-icon class="mr-1">
                      <img
                        style="height: 20px; width: 20px"
                        :src="`/img/icons/sidebar/${menu.icon}`"
                      />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title style="color: white" class>{{ menu.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>

                <div class="mt-auto mb-lg-auto" />
              </v-list>
            </v-col>
          </v-row>
          <v-layout style="position: relative; bottom: 0" align-end>
            <v-list>
              <v-list-item class="pl-8" @click="logout()">
                <v-list-item-icon class="mr-3">
                  <img
                    style="height: 25px; width: 25px"
                    src="/img/icons/sidebar/exit-icon.svg"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="color: white; font-size: 18px" class>Sair</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-layout>
          <img
            class="screenshot-right"
            :src="menu.screenshotImage"
          />
        </v-navigation-drawer>
      </v-layout>
    </v-expand-x-transition>
  </span>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      drawer: this.menu && this.menu.hamburguer,
      items: [
        { title: "FAQ", icon: "question", link: "/ajuda" },
        { title: "Helpdesk", icon: "question", link: "/ajuda" },
      ],
    };
  },
  watch: {
    $route() {
      this.checkToolbar();
    },
  },
  mounted() {
    this.checkToolbar();
  },
  computed: {
    ...mapState(["menu", "badges", "app"]),
    user() {
      return this.$auth.user();
    },
    rotaroot() {
      return !!this.$route.meta.root;
    },
    rotatour() {
      return !!this.$route.meta.tour;
    },
  },
  methods: {
    ...mapMutations([
      "toggleMenul",
      "toggleMenur",
      "updateMainSearch",
      "updateMenuState",
      "toggleShowTour",
      "toggleShowHamgurber",
    ]),
    openMenu(menu) {
      if (menu.type === 'CUSTOM') {
        this.$util[menu.options]()
        this.$util.link(menu.link, null, null);
        this.toggleShowHamgurber(false);
      } else {
        this.$util.link(menu.link, null, JSON.parse(menu.options));
        this.toggleShowHamgurber(false);
      }
    },
    acionartour() {
      this.toggleShowTour();
    },
    conectarEmpresa() {
      this.dialog = true;
    },
    rotaatual() {
      return this.$route.meta.title
        ? this.$route.meta.title
        : this.$route.params.title
        ? this.$route.params.title
        : "";
    },
    title: function () {
      return this.user.name;
    },
    voltar() {
      this.$router.go(-1);
    },
    checkToolbar() {
      const toolbar = !this.rotaroot || !!this.$route.meta.forcetoolbar;
      this.updateMenuState({ toolbar: toolbar });

      try {
        const toolbarBehavior = !!this.$route.meta.toolbarBehavior;
        this.updateMenuState({
          toolbarBehavior: toolbarBehavior,
        });
        //eslint-disable-next-line
      } catch (err) {}
    },
    logout() {
      this.toggleShowHamgurber(false);
      this.$auth.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style>
#toolbarContainer .v-input .v-icon {
  font-size: 16px !important;
}
.noborder.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.noborder.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
#toolbarContainer .v-input__slot {
  border: thin solid #8080804f;
  border-radius: 5px;
  background-color: white;
}

.menu {
  cursor: pointer;
  font-size: 12px;
}

.user-name {
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding-left: 0px;
  padding-top: 8px;
}

.screenshot-right {
  position: absolute;
  height: 70vh;
  right: -220px;
  top: 20%;
  filter: drop-shadow(-16px 20px 32px rgba(233, 235, 253, 0.16));
  border-radius: 20px;
}
</style>
